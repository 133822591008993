
$(function() {
    $('#main').click(function(){
        // $('.main-bg-1').toggleClass('active');
        $('.main-bg-2').toggleClass('active')
    })
    $('.main__button').hover(function(){
        $('.main-bg-2').addClass('active')
    },function(){
        $('.main-bg-2').removeClass('active')
    })
    
});