// Vendor
import '../../vendor/vendor'

// Components
import '../../components/components'
$(window).on("load", function (e) {
    new WOW().init()

    var activeNums = 0
    window.onscroll = function() {scrollEvents()};

    function scrollEvents() {
        console.log(1)
        var x = document.documentElement.scrollTop;
        $('#track_scroll').css('background-position-x', parseInt(-x / 1) + 'px');

        
        var yes = $("#counters").offset().top;
        if((x + 1000) > yes){
            if(activeNums == 0){
                $({blurRadius: 5}).animate({blurRadius: 0}, {
                    duration: 1000,
                    easing: 'swing',
                    step: function() {
                        $(".counters__title").css({
                            "-webkit-filter": "blur("+this.blurRadius+"px)",
                            "filter": "blur("+this.blurRadius+"px)"
                        });
                    }
                });
                var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(' ');
                $(".counters__title").each(function() {
                    var tcount = $(this).data("count");
                    $(this).animateNumber({ number: tcount,
                        easing: 'easeInQuad',
                        "font-size": "40px",
                        numberStep: comma_separator_number_step},
                        5000);
                });
                activeNums = 1
            }
        }
    }
    
})
