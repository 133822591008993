// Form
import './form/form'

// Hamburger
import './hamburger/hamburger'

import './avtopark/avtopark'
import './counters/counters'
import './about/about'
import './main/main'

$(document).ready(function(){
	$(".mainmenu__item").on("click","a", function (event) {
		event.preventDefault(); //опустошим стандартную обработку
		var id  = $(this).attr('href'), //заберем айдишник блока с параметром URL
			top = $(id).offset().top; //определим высоту от начала страницы до якоря
        top = top - 200;
	$('body,html').animate({scrollTop: top}, 1000); //сделаем прокрутку за 1 с
	});
});
