$(function() {
    $('.avtopark__list li').click(function(){
        image = $(this).attr('data-image')
        $('.avtopark__image img').removeClass('active')
        $('.avtopark__image img').removeClass('bounceInRight')
        
        $('.avtopark__image img[data-image="'+image+'"]').addClass('active')
        $('.avtopark__image img[data-image="'+image+'"]').addClass('wow')
        $('.avtopark__image img[data-image="'+image+'"]').addClass('animated')
        $('.avtopark__image img[data-image="'+image+'"]').addClass('slideInRight')
        
    })
});